/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** Algolia start.  There is a small bug that causes Algolia to throw an error on initialization in Angular 6. The current workaround is to add the following code to polyfills.ts */
(window as any).process = {
	env: { DEBUG: undefined },
};
import 'core-js/es6/reflect';
/** IE10 and IE11 require the following for NgClass support on SVG elements */
import 'classlist.js'; // Run `npm install --save classlist.js`.
/** IE10 and IE11 require the following to support `@angular/animation`. */
import 'web-animations-js'; // Run `npm install --save web-animations-js`.
/** IE11 requires the following to support ES6 Proxy */
import 'proxy-polyfill/proxy.min.js'; //Run `npm install --save proxy-polyfill`
/** IE10 and IE11 require the following to support ES7 Object.values and Object.entries*/
import 'es7-object-polyfill';
/** IE10 and IE11 require the following to support .toBlob method of HTMLCanvasElement*/
import 'mdn-polyfills/HTMLCanvasElement.prototype.toBlob';
/** IE10 and IE11 require the following to support fetch API*/
import 'whatwg-fetch';

/** For smooth scrolling in IE, Edge, Safari */
import smoothscroll from 'smoothscroll-polyfill';
// kick off the polyfill!
smoothscroll.polyfill();

import 'core-js/es6/promise';
import 'core-js/es6/object';

import 'core-js/es7/reflect';
/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
// Line immediately below for IE specifically, and only when dev tools is open.
(window as any).__Zone_enable_cross_context_check = true;
import 'zone.js/dist/zone'; // Included with Angular CLI.


/** ALL Firefox browsers require the following to support `@angular/animation`. **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
